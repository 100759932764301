import React, { useState, useEffect } from "react";
import axios from "axios";
import localforage from "localforage";
import "../css/form.css";
import assemblyData from "../assemblyData.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Dashboard from "./Dashboard";

function NewBoothPramukhForm() {
  const [formData, setFormData] = useState({
    phoneNumber: "",
    callingStatus: "",
    effectivenessScale: "",
    beneficiariesCount: "",
    performanceScale: "",
    membersEnrolled: "",
    userId: "",
    userName: "",
  });

  const [step, setStep] = useState(1);
  const [assemblies, setAssemblies] = useState([]);
  const [booths, setBooths] = useState([]);
  const [userId, setUserId] = useState([]);

  useEffect(() => {
    localforage.getItem("ID").then((userId) => {
      if (userId) {
        setFormData((prevData) => ({ ...prevData, userId }));
        setUserId(userId);
      } else {
        alert("User ID not found");
      }
    });

    localforage.getItem("userName").then((userName) => {
      if (userName) {
        setFormData((prevData) => ({ ...prevData, userName }));
      } else {
        alert("User name not found");
      }
    });

    const uniqueAssemblies = [
      ...new Set(
        assemblyData
          .sort((a, b) => {
            const aNum = parseInt(a.constituency.split("-")[0]);
            const bNum = parseInt(b.constituency.split("-")[0]);
            return aNum - bNum;
          })
          .map((item) => item.constituency)
      ),
    ];
    setAssemblies(uniqueAssemblies);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    switch (step) {
      case 1:
        if (!formData.phoneNumber) {
          alert("Please enter the Shivdoot phone number.");
          return;
        }
        setStep(step + 1);
        break;
      case 2:
        if (!formData.callingStatus) {
          alert("Please select the calling status.");
          return;
        }
        if (formData.callingStatus === "Answered") {
          setStep(step + 1);
        } else {
          axios
            .post("http://3.110.158.33:5000/api/boothformnew/submit", formData)
            .then(() => {
              alert("Form submitted successfully");
              setFormData({
                phoneNumber: "",
                callingStatus: "",
                assembly: "",
                boothNumber: "",
                effectivenessScale: "",
                beneficiariesCount: "",
                performanceScale: "",
                membersEnrolled: "",
                userId: formData.userId,
                userName: formData.userName,
              });
              setStep(1);
            })
            .catch((error) => alert("Error submitting form"));
        }
        break;
        case 3:
        if (!formData.assembly) {
          alert("Please select an assembly.");
          return;
        }
        setStep(step + 1);
        break;
      case 4:
        setStep(step + 1);
        break;
      case 5:
        if (!formData.effectivenessScale) {
          alert(
            "Please rate the effectiveness of the Mukhya Mantri Ladki Bahin Yojana."
          );
          return;
        }
        setStep(step + 1);
        break;
      case 6:
        if (!formData.beneficiariesCount) {
          alert("Please enter the number of beneficiaries.");
          return;
        }
        setStep(step + 1);
        break;
      case 7:
        if (!formData.performanceScale) {
          alert("Please rate the performance of Shivsena.");
          return;
        }
        setStep(step + 1);
        break;
      case 8:
        if (!formData.membersEnrolled) {
          alert("Please enter the number of members enrolled.");
          return;
        }
        setStep(step + 1);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://3.110.158.33:5000/api/boothformnew/submit", formData)
      .then((response) => {
        alert("Form submitted successfully");
        setFormData({
          phoneNumber: "",
          callingStatus: "",
          assembly: "",
          boothNumber: "",
          effectivenessScale: "",
          beneficiariesCount: "",
          performanceScale: "",
          membersEnrolled: "",
          userId: formData.userId,
          userName: formData.userName,
        });
        setStep(1);
      })
      .catch((error) => alert("Error submitting form"));
  };

  const handleAssemblyChange = (e) => {
    const selectedAssembly = e.target.value;
    setFormData({ ...formData, assembly: selectedAssembly });

    const filteredBooths = assemblyData
      .filter((item) => item.constituency === selectedAssembly)
      .map((item) => item.booth);
    setBooths(filteredBooths);
  };

  return (
    <div>
      <Dashboard />
      <div className="main-container">
        <h1 className="header-main">Shivdoot Registration</h1>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
          {step === 1 && (
              <div className="form-group">
                <label>
                  Shivdoot Phone Number (Copy paste from the Dashboards)
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  style={{ margin: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            )}
            {step === 2 && (
              <div className="form-group">
                <label>Calling Status</label>
                <select name="callingStatus" onChange={handleChange} required>
                  <option value="">Select Status</option>
                  <option value="Answered">Answered</option>
                  <option value="Invalid number">Invalid number</option>
                  <option value="Not answered">Not answered</option>
                  <option value="Switch off">Switch off</option>
                  <option value="Not interested">Not interested</option>
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 3 && (
              <div className="form-group">
                <label>Assembly (Choose from dropdown list)</label>
                <select
                  name="assembly"
                  value={formData.assembly}
                  onChange={handleAssemblyChange}
                  required
                >
                  <option value="">Select Assembly</option>
                  {assemblies.map((assembly, index) => (
                    <option key={index} value={assembly}>
                      {assembly}
                    </option>
                  ))}
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 4 && (
              <div className="form-group">
                <label>Booth Number</label>
                <select
                  name="boothNumber"
                  value={formData.boothNumber}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Booth</option>
                  {booths.map((booth, index) => (
                    <option key={index} value={booth}>
                      {booth}
                    </option>
                  ))}
                </select>
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 5 && (
              <div className="form-group">
                <label>
                  Q1. On a scale of 1-10, how effective is the Mukhya Mantri
                  Ladki Bahin Yojana in your Village/Ward?
                </label>
                <input
                  type="number"
                  name="effectivenessScale"
                  value={formData.effectivenessScale}
                  onChange={handleChange}
                  min="1"
                  max="10"
                  required
                  style={{ marginLeft: "0px" }}
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
              </div>
            )}
            {step === 6 && (
              <div className="form-group">
                <label>
                  Q2. How many Ladki Bahin Yojana’s beneficiaries are there in
                  your village/ward?
                </label>
                <input
                  type="number"
                  name="beneficiariesCount"
                  value={formData.beneficiariesCount}
                  onChange={handleChange}
                  style={{ marginLeft: "0px" }}
                  required
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 7 && (
              <div className="form-group">
                <label>
                  Q3. On a scale of 1-10, how would be the performance of
                  Shiv-Sena in your booth for the upcoming assembly elections?
                </label>
                <input
                  type="number"
                  name="performanceScale"
                  value={formData.performanceScale}
                  onChange={handleChange}
                  min="1"
                  max="10"
                  style={{ marginLeft: "0px" }}
                  required
                />
                <button type="button" onClick={handleNext}>
                  Next
                </button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 8 && (
              <div className="form-group">
                <label>
                  Q4. How many people did you enrol as Shiv-Sena’s member in the
                  party’s membership drive campaign?
                </label>
                <input
                  type="number"
                  name="membersEnrolled"
                  value={formData.membersEnrolled}
                  onChange={handleChange}
                  style={{ marginLeft: "0px" }}
                  required
                />
                <button type="submit">Submit</button>
                <button type="button" onClick={handleBack}>
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" />
                </button>
              </div>
            )}
            {step === 0 && (
              <div className="form-end">
                <h3>Form Ended!!!!!</h3>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewBoothPramukhForm;
